import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Container from "../../components/Container";
import ItalianCampaignCallToActions from "../../components/ItalianCampaignCallToActions";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["common"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default function Struttura() {
    return (
        <Layout>
            <Seo title="Scopri Assisi e la verde Umbria" />
            <Container>
                <h1>Scopri Assisi e la verde Umbria</h1>
                <StaticImage
                    src="../../../assets/images/struttura.jpg"
                    alt="L'Hotel"
                    className="rounded-4xl mb-4"
                    aspectRatio={16 / 10}
                    layout="fullWidth"
                    transformOptions={{ fit: "cover" }}
                />
                <p>Benvenuti all’Hotel Moderno!</p>
                <p>
                    Immerso nella splendida cornice <b>ai piedi di Assisi</b> è
                    un grande punto di riferimento per chi voglia passare un
                    soggiorno all’insegna del relax, del trekking o di lunghe
                    passeggiate spirituali nella verde Umbria.
                </p>
                <p>
                    Le nostre stanze recentemente rinnovate vi offriranno tutti
                    i comfort e le coccole necessarie. La struttura si trova
                    nelle vicinanze di tutti i punti nevralgici ed i servizi
                    della città.
                </p>
                <p>
                    Comodamente raggiungibile con qualsiasi mezzo di trasporto
                    l’hotel vanta una <b>tranquilla posizione</b> lontana dal
                    caos cittadino.
                </p>
                <p>
                    La <b>stazione dei treni appena di fronte</b> permetterà ai
                    nostri clienti di lasciare la propria auto direttamente a
                    casa e farsi un tour della nostra bella Umbria senza
                    pensieri, oppure il nostro{" "}
                    <b>ampio parcheggio interno gratuito</b> vi permetterà
                    comunque di spostarvi senza alcun problema.
                </p>
                <p>
                    Avete uno <b>spettacolo a teatro</b>? L’hotel si trova a
                    pochi passi e si può raggiungere anche a piedi!
                </p>
                <p>
                    Il nostro è un <b>hotel bike-friendly</b> e potrete
                    percorrere tratte bellissime a bordo della vostra bici come
                    la Spoleto-Norcia e se la bici ha un problema gli attrezzi
                    per manutenzione ce li abbiamo noi!
                </p>
                <p>
                    Vi vizieremo con la nostra cucina tipica,{" "}
                    <b>
                        piatti realizzati in giornata con materie prime fresche
                    </b>{" "}
                    e tante varianti per i più piccoli!
                </p>
                <p>
                    I vostri bambini sono i benvenuti nel nostro hotel, grazie
                    ad una lunga tradizione di family ospitality,
                </p>
                <p>
                    Amanti dell’arte, dell’architettura, della cultura, Assisi è
                    una piccola grande perla tutta da scoprire.
                </p>
                <p>
                    Prenota il tuo soggiorno e in attesa delle vacanze ogni
                    lunedì sembrerà più leggero!
                </p>
                <ItalianCampaignCallToActions />
            </Container>
        </Layout>
    );
}
